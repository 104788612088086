.checkboxes {
    margin-top: 15px;
}

.checkboxes a {
    color: var(--theme-blue);
}

.checkboxes a:visited {
    color: var(--dark-theme-blue);
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 30px;
}

.loading-indicator {
    border: 5px solid var(--internal-background-grey);
    border-top: 5px solid var(--theme-blue);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}