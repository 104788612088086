.slider-container {
    margin-top: 10px;
    width: 580px;
}

.text-fields-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    width: 580px;
}

.text-field-sizer {
    width: 275px;
}