.segment-title {
    color: var(--theme-grey);
    font-size: var(--font-size-tieny);
    font-family: var(--font-family-bold);
    margin-bottom: 15px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.body::-webkit-scrollbar {
    display: none;
}

.segment-body {
    width: 100%;
    display: inline-block;
    background-color: white;
    margin-bottom: 30px;
    border-radius: 4px;
}