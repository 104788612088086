.how-it-works {
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 50px;
    margin-bottom: auto;
}

.section-top {
    display: flex;
    justify-content: space-between;
}

.section-bottom {
    padding-left: 30px;
    padding-right: 30px;
}

.content-section {
    width: 50%;
    padding: 30px;
}

.content-section h1,
.content-section h2,
.content-section p {
    text-align: left;
    color: black;
}

.content-section h1.section-title {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
}

.section-heading {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: var(--font-size-larger);
    font-family: var(--font-family-regular);
}

.section-description {
    margin-top: 15px;
    font-size: var(--font-size-medium);
    font-family: var(--font-family-regular);
    line-height: 1.5;
}

.buttons-container {
    display: flex;
    gap: 10px;
    margin-top: 45px;
    justify-content: flex-start;
}

.sponsors-button,
.investors-button {
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-small);
}

.sponsors-button.selected,
.investors-button.selected {
    background-color: var(--theme-blue);
    color: white;
    border: none;
}

.sponsors-button:not(.selected),
.investors-button:not(.selected) {
    background-color: white;
    color: black;
    border: 1px solid black;
}

.right-content-section {
    width: 50%;
    padding-top: 68px;
    position: relative;
}

.navigation-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.chevron-container {
    display: flex;
    gap: 15px;
}

.chevron-button {
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chevron-button.disabled {
    background-color: transparent;
    color: darkgray;
    cursor: default;
}

.how-it-works-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.how-it-works-image {
    width: 100%;
    border: 1px solid var(--light-theme-grey);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0033ff17;
    filter: blur(10px);
    transition: background-color 0.5s ease;
}

.how-it-works-image.image-loaded .image-overlay {
    background-color: transparent;
}

.how-it-works-image img {
    width: 100%;
    height: auto;
    display: block;
}

.text-container {
    position: absolute;
    white-space: nowrap;
    margin-top: 20px;
}

.text-line {
    display: flex;
    align-items: center;
    font-family: var(--font-family-bold);
    cursor: pointer;
    transition: color 0.3s ease;
    margin-bottom: 10px;
    margin-top: 0;
    white-space: nowrap;
    overflow: hidden;
}

.focused {
    color: black;
}

.text-line:not(.focused) {
    padding-top: 6px;
    color: var(--light-theme-grey);
}

.number {
    font-size: var(--font-size-larger);
    margin-right: 15px;
}

.text {
    font-size: var(--font-size-medium);
}

.focused .number {
    font-size: var(--font-size-extra);
}

.focused .text {
    font-size: var(--font-size-larger);
}