.input-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.radio-buttons {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
}

.text-fields-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 580px;
}

.text-field-sizer {
    width: 275px;
}

.drop-down-sizer {
    width: 580px;
    margin-top: 9.5px;
}