.internal-app {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.internal-body {
    position: fixed;
    top: 64px;
    left: 0;
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--internal-background-grey);
}