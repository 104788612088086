.our-clients-main {
    margin-top: 140px;
    align-items: flex-start;
}

.our-clients-top {
    width: 80%;
    margin: auto;
}

.section-title {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
}

.logo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 40px;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 30px;
}

.logo-item {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-item img {
    max-height: 40px;
    width: auto;
}

.testimonial-full-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-top: 60px;
}

.testimonial-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    width: 80%;
    margin: 0 auto;
}

.testimonial {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 30px;
    width: 26%;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.testimonial-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.testimonial-author,
.testimonial-company {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-regular);
}

.testimonial-description {
    font-size: var(--font-size-medium);
    font-family: var(--font-family-regular);
    text-align: left;
    line-height: 1.5;
    margin-top: 15px;
}

.story-button {
    background-color: var(--light-theme-blue);
    color: var(--theme-blue);
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-small);
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: left;
    align-self: flex-start;
}

.story-button:hover {
    background-color: var(--theme-blue);
    color: white;
}

.testimonial-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.testimonial-description,
.story-button {
    text-align: left;
}

.chevron {
    padding: 0 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.chevron.left {
    left: calc(5% - 10px);
}

.chevron.right {
    right: calc(5% - 10px);
}

.chevron.disabled {
    color: #ccc;
    cursor: default;
}