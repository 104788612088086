.app-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.terms-container {
    margin: 20px;
    margin-top: 70px;
    padding: 20px;
    border: 1px solid var(--border-theme-grey);
    background-color: var(--internal-background-grey);
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}