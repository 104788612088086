.selectables-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    margin: 0 8px;
}

.selectable-button {
    background-color: white;
    border: 1px solid var(--border-theme-grey);
    border-radius: 8px;
    padding: 10px;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-tiny);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}

.selectable-button.selected {
    background-color: var(--theme-blue);
    color: white;
}

.check-icon {
    color: white;
}