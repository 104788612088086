.spread-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: flex-start;
    margin: 30px;
}

.display-window {
    position: relative;
    border: 1px solid var(--border-theme-grey);
    border-radius: 8px;
    padding: 10px;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-tiny);
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: default;
    opacity: 1;
}

.background {
    border-radius: 8px;
}