.sidebar {
    width: 220px;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    transition: width 0.5s ease;
}

.sidebar.collapsed {
    width: 80px;
}

.menu-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.menu-heading {
    font-size: var(--font-size-teenie);
    font-family: var(--font-family-bold);
    color: var(--theme-grey);
    margin-bottom: 10px;
    margin-left: 36px;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 10px;
    color: var(--theme-grey);
    font-size: var(--font-size-tiny);
    text-decoration: none;
}

.menu-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.menu-item:hover {
    color: var(--theme-blue);
}

.selected {
    color: var(--theme-blue);
    font-size: var(--font-size-tiny);
}