.input-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.page-header-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.page-header-tiny-text {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
    margin-bottom: 10px;
}

.page-header-descriptor-text {
    font-size: var(--font-size-large);
    font-weight: var(--font-family-thin);
    color: black;
    margin: 0;
}

.image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.image {
    width: 580px;
    border-radius: 8px;
    overflow: hidden;
}

.text-fields-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 580px;
}

.text-field-sizer-large {
    margin-top: 10px;
    width: 580px;
}