:root {
    --radio-theme-blue: #E2E8F0;
    --side-bar-button-highlight: #fee5e5;
    --light-theme-blue: #F2F1F1;
    --bright-box-theme: #FE5131;
    --box-theme: #1D444B;
    --theme-blue: #7D1A24;
    --dark-theme-blue: #5E141A;
    --border-theme-grey: #E0E0E0;
    --table-theme-grey: #F6F8FA;
    --light-theme-grey: #D1D0D0;
    --theme-grey: #a3a3a3;
    --internal-background-grey: #F6F8FA;
    --discrete-theme-grey: #5B5B5B;
    --font-size-teenie: 10px;
    --font-size-tieny: 12px;
    --font-size-tiny: 14px;
    --font-size-small: 16px;
    --font-size-medium: 18px;
    --font-size-lardium: 20px;
    --font-size-large: 24px;
    --font-size-larger: 28px;
    --font-size-extra: 36px;
    --font-size-extra-large: 56px;
    --font-family-regular: "Roc Grotesk Regular", sans-serif;
    --font-family-thin: "Roc Grotesk Thin", sans-serif;
    --font-family-bold: "Roc Grotesk Medium", sans-serif;
}