.input-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.text-fields-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 580px;
}

.text-field-sizer {
    width: 275px;
}

.text-field-sizer-large {
    margin-top: 10px;
    width: 580px;
}