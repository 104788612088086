.checkboxesContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.checkboxButton {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    position: relative;
}

.checkboxButton input[type='checkbox'] {
    position: absolute;
    width: 20px;
    height: 20px;
    outline: none;
    cursor: pointer;
    appearance: none;
    background-color: white;
    border: 2px solid var(--radio-theme-blue);
    border-radius: 4px;
    margin: 0;
}

.checkboxButton input[type='checkbox']:checked+.checkboxIconWrapper {
    border-radius: 4px;
}

.checked {
    background-color: var(--theme-blue);
    border-color: var(--theme-blue);
}


.checkboxButton .checkboxIconWrapper {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 15px;
}

.checkboxButton .check {
    color: white;
    font-size: 14px;
}