.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 75vh;
    margin-top: 15vh;
    margin-bottom: 10vh;
}

.page-header-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.page-header-tiny-text {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
    margin-bottom: 10px;
}

.page-header-descriptor-text {
    font-size: var(--font-size-large);
    font-weight: var(--font-family-thin);
    color: black;
    margin: 0;
}

.cards-container {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 30px;
    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.shield-icon {
    font-size: 2.5rem;
    color: black;
    height: 56px;
}

.chart-icon {
    font-size: 2.5rem;
    color: black;
    padding-top: 8px;
    height: 48px;
}

.card-description {
    font-size: var(--font-size-small);
    font-family: var(--font-family-regular);
    text-align: center;
    line-height: 1.5;
    margin-top: 30px;
    margin-bottom: 30px;
}

.story-button {
    background-color: var(--light-theme-blue);
    color: var(--theme-blue);
    border: none;
    border-radius: 20px;
    padding: 10px 40px;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-small);
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: center;
}

.story-button:hover {
    background-color: var(--theme-blue);
    color: white;
}

@media (max-width: 768px) {
    .cards-container {
        flex-direction: column;
        gap: 20px;
    }
}