.dropdown {
    position: relative;
}

.dropdownHeader {
    width: calc(100% - 30px);
    padding: 10px;
    padding-right: 20px;
    border: 1px solid var(--border-theme-grey);
    border-radius: 5px;
    background-color: white;
    font-size: var(--font-size-small);
    color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdownLabel {
    font-size: var(--font-size-teenie);
    color: var(--theme-grey);
    font-family: var(--font-family-bold);
}

.dropdownSelected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4px;
}

.chevronIcon {
    margin-left: 8px;
}

.dropdownOptions {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid var(--border-theme-grey);
    border-radius: 5px;
}

.dropdownOption {
    padding: 10px;
    transition: background-color 0.2s ease;
    cursor: pointer;
}

.dropdownOption:hover {
    background-color: var(--light-theme-blue);
    color: var(--dark-theme-blue);
}

.selectedOption {
    background-color: var(--light-theme-blue);
    color: var(--dark-theme-blue);
}