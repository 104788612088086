.signin-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 70vh;
    margin-top: 15vh;
    margin-bottom: 15vh;
}

.signin-title-container {
    text-align: center;
}

.signin-tiny-text {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
}

.signin-welcome-text {
    font-size: var(--font-size-large);
    font-weight: var(--font-family-thin);
    color: black;
    margin: 10px 0;
}

.signin-button {
    margin-top: 30px;
    padding: 10px 20px;
    background-color: var(--theme-blue);
    color: white;
    border: none;
    border-radius: 20px;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
    cursor: pointer;
}

.signin-button.disabled {
    background-color: var(--light-theme-grey);
    cursor: not-allowed;
}

.textfield-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 360px;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 30px;
}

.loading-indicator {
    border: 5px solid var(--internal-background-grey);
    border-top: 5px solid var(--theme-blue);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}