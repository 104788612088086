.action-button {
    background-color: var(--internal-background-grey);
    color: black;
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
}

.button-icon {
    width: 20px;
    height: 20px;
}

.button-text {
    font-family: var(--font-family-bold);
}

.sign-contract-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttons-group {
    display: flex;
    gap: 30px;
    padding: 30px
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
}

.loading-indicator {
    border: 5px solid var(--internal-background-grey);
    border-top: 5px solid var(--theme-blue);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}