.bottom-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    background-color: var(--box-theme);
    color: white;
    font-family: var(--font-family-regular);
    border-radius: 15px;
    width: calc(100% - 90px);
    margin: 180px 45px 45px 45px;
    box-sizing: border-box;
}

.links-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 60px;
    gap: 50px;
}

.links-column {
    display: flex;
    flex-direction: column;
}

.links-column h1 {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    cursor: default;
    color: white;
}

.links-column a {
    font-size: var(--font-size-tiny);
    text-decoration: none;
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
}

.social-media-icons {
    display: flex;
    justify-content: space-between;
    width: 240px;
    font-size: 1.7em;
}

.social-media-icon {
    cursor: pointer;
}

.line {
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    margin: 30px 0px;
}

.brand-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.brand-name {
    font-size: var(--font-size-small);
    font-family: var(--font-family-bold);
    color: white;
    margin: 0;
}