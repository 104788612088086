.what-we-do {
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 50px;
    margin-bottom: auto;
}

.content-section {
    width: 50%;
    padding: 30px;
}

.content-section h1,
.content-section h2,
.content-section p {
    text-align: left;
    color: black;
}

.content-section h1.section-title {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
}

.section-heading {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: var(--font-size-larger);
    font-family: var(--font-family-regular);
}

.section-description {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: var(--font-size-medium);
    font-family: var(--font-family-regular);
    line-height: 1.5;
}

.learn-more-button {
    position: relative;
    margin-top: 30px;
    color: black;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-small);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.learn-more-button .fa-icon {
    padding-left: 0;
}

.image-section {
    width: 50%;
    padding: 30px;
}

.image-container {
    width: 100%;
    height: 100%;
    background-color: #0033ff17;
    border-radius: 12px;
}

.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
}

.underline {
    position: relative;
    margin-right: 15px;
}

.underline::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    height: 1px;
    background: black;
}