/* TopBar styles */
.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.logo {
    width: 198.03px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo.collapsed {
    width: 78px;
}

.brand {
    margin-right: auto;
    margin-left: 30px;
    max-height: 39px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.brand img {
    max-height: 22px;
    width: auto;
}

.main-panel {
    width: calc(100vw - 198.03px);
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: width 0.5s ease;
}

.main-panel.collapsed {
    width: calc(100vw - 78px);
}

.search {
    margin-left: 30px;
    width: 300px;
    height: 50%;
    padding-left: 10px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: var(--internal-background-grey);
}

.search-bar {
    display: flex;
    align-items: center;
}

.search-icon {
    width: 15px;
    height: 15px;
    margin-right: 0px;
}

.search-input {
    flex-grow: 1;
    padding: 8px;
    border: none;
    background: transparent;
    font-size: var(--font-size-tiny);
    color: black;
    outline: none;
}

.actions {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 30px;
}

.avatar-circle {
    width: 32px;
    height: 32px;
    background-color: var(--internal-background-grey);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;
}

.avatar-icon {
    width: 18px;
    height: 18px;
}

.avatar-profile {
    width: 42px;
    height: 42px;
    background-color: var(--internal-background-grey);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;
}

.profile-pic {
    width: 42px;
    height: 42px;
    border-radius: 50%;
}

.profile-name {
    display: flex;
    align-items: center;
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    cursor: pointer;
    margin-left: 15px;
}

.chevron-down {
    margin-left: 10px;
}