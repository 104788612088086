.radioButtonContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.horizontal {
    flex-direction: row;
}

.radioButton {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.radioButton input[type="radio"] {
    margin-right: 6px;
}

.radioButton input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 3px solid var(--radio-theme-blue);
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    background-color: white;
}

.radioButton input[type="radio"]:checked {
    background-color: white;
    border: 5.5px solid var(--theme-blue);
}

.radioButton input[type="radio"]:checked::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
}