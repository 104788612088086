.use-case {
    width: 90%;
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 110px;
    margin-bottom: auto;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.use-case-title {
    color: var(--dark-theme-blue);
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
}

.tranquil-in-action {
    font-size: var(--font-size-larger);
    margin-top: 0;
    margin-bottom: 10px;
}

.content {
    border: 1px solid var(--light-theme-grey);
    border-radius: 20px;
    background-color: white;
    padding-top: 8%;
    padding-left: 8%;
    padding-right: 8%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.left-content {
    flex-basis: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.meet-joaquim {
    font-size: var(--font-size-extra);
    font-family: var(--font-family-regular);
    margin-bottom: 15px;
    margin-top: 0;
}

.description {
    font-size: var(--font-size-medium);
    line-height: 1.5;
}

.right-content {
    flex-basis: 33.33%;
}

.image-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.image-container img {
    border-radius: 12px;
    height: 100%;
    width: 100%;
}

.joaquim-image {
    width: 100%;
    height: auto;
}

.read-more-container {
    width: 100%;
    text-align: center;
    padding-top: 8%;
    padding-bottom: 3%;
}

.read-more-button {
    background-color: transparent;
    color: black;
    border: none;
    cursor: pointer;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-small);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.expand-icon {
    margin-left: 8px;
    font-size: 14px;
}