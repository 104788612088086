.squares-container-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.squares-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
    margin-bottom: 30px;
}

.square-container {
    position: relative;
}

.square {
    width: 150px;
    height: 150px;
    border: 1px solid var(--border-theme-grey);
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.square.highlighted {
    border: 1px solid var(--theme-blue);
}

.square-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    width: 60px;
    height: 60px;
    color: black;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    margin-top: 5px;
    color: black;
}

.checkbox {
    position: absolute;
    top: calc(100%);
    left: 50%;
    transform: translateX(-50%);
}

.unavailable {
    opacity: 0.5;
    cursor: not-allowed;
}

.unavailable-text {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translate(-50%, -50%);
    color: grey;
}

.button-group {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.slider-container {
    width: 580px;
}