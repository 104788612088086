.introduction {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 62px;
    margin-bottom: auto;
    background-color: white;
}

.blue-rectangle {
    margin-left: 30px;
    margin-right: 30px;
    width: 100%;
    border-radius: 28px;
    padding: 30px;
    color: white;
    text-align: center;
    background: var(--box-theme);

}

.introduction-content {
    text-align: center;
    margin-top: 60px;
}

.intro-heading {
    font-size: var(--font-size-extra-large);
    color: white;
    margin-bottom: 20px;
    font-family: var(--font-family-regular);
}

.intro-description {
    font-size: var(--font-size-large);
    color: white;
    opacity: 0.75;
    margin-bottom: 90px;
    font-family: var(--font-family-regular);
}

.button-content {
    margin-bottom: 60px;
}

.start-now-button {
    background-color: white;
    color: var(--theme-blue);
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
}

.start-now-chevron {
    margin-left: 5px;
}