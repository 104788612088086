.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 75vh;
    margin-top: 15vh;
    margin-bottom: 10vh;
}

.page-header-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.page-header-tiny-text {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
    margin-bottom: 10px;
}

.page-header-descriptor-text {
    font-size: var(--font-size-large);
    font-weight: var(--font-family-thin);
    color: black;
    margin: 0;
}

.button-group {
    display: flex;
    flex-direction: column;
    width: 150px;
    gap: 5px;
    align-items: center;
}

.next-button {
    background-color: var(--theme-blue);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 40px;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-button {
    background-color: transparent;
    color: black;
    border: none;
    border-radius: 20px;
    padding: 10px;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-tiny);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.chevron-icon {
    margin-right: 5px;
}

.next-button.disabled,
.next-button:disabled {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed;
}

.back-button:hover:not(.disabled) {
    color: var(--discrete-theme-grey);
}

.next-button:hover:not(.disabled) {
    background-color: var(--light-theme-blue);
    color: var(--theme-blue);
}