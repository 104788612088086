.squares-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.square-container {
    position: relative;
}

.square {
    width: 150px;
    height: 150px;
    border: 1px solid var(--border-theme-grey);
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 15px;
}

.square-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    width: 60px;
    height: 60px;
    color: black;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    margin-top: 5px;
    color: black;
}

.checkbox {
    position: absolute;
    top: calc(100%);
    left: 50%;
    transform: translateX(-50%);
}

.unavailable {
    opacity: 0.5;
    cursor: not-allowed;
}

.unavailable-text {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translate(-50%, -50%);
    color: grey;
}

.button-group {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.checkboxesContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.checkboxButton {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    position: relative;
}

.checkboxButton input[type='checkbox'] {
    position: absolute;
    width: 20px;
    height: 20px;
    outline: none;
    cursor: pointer;
    appearance: none;
    background-color: white;
    border: 2px solid var(--radio-theme-blue);
    border-radius: 4px;
    margin: 0;
}

.checkboxButton input[type='checkbox']:checked+.checkboxIconWrapper {
    background-color: var(--theme-blue);
    border-color: var(--theme-blue);
    border-radius: 4px;
}

.checkboxButton .checkboxIconWrapper {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.checkboxButton .check {
    color: white;
    font-size: 14px;
}