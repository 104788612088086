.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.stat-card {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 30px;
    position: relative;
}

.circle {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    background-color: var(--theme-blue);
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.content {
    display: flex;
    flex-direction: column;
}

.value {
    font-size: var(--font-size-lardium);
    font-family: var(--font-family-bold);
    color: black;
}

.descriptor {
    font-size: var(--font-size-tiny);
    color: var(--theme-grey);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.vertical-line {
    width: 1px;
    height: 46px;
    background-color: var(--light-theme-grey);
    margin-right: 30px;
}