.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.brand {
    margin-right: auto;
    margin-left: 30px;
    max-height: 39px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.brand img {
    max-height: 25px;
    width: auto;
}

.login-signup {
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.question-text {
    background-color: transparent;
    color: black;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
    font-family: var(--font-family-regular);
    transition: box-shadow 0.3s ease-in-out;
    pointer-events: none;
}

.signup-button {
    background-color: var(--theme-blue);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
}

.signup-chevron {
    margin-left: 5px;
}