.app-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.article {
    font-family: var(--font-family-regular);
    background-color: var(--light-theme-blue);
    margin-top: 70px;
    padding: 60px;
}

.title {
    font-family: var(--font-family-bold);
    font-size: var(--font-size-large);
    color: var(--box-theme);
}

.content {
    font-size: var(--font-size-medium);
    line-height: 1.5;
    color: var(--theme-grey);
}

.image {
    max-width: 100%;
    height: auto;
}