.body {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: var(--internal-background-grey);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.body::-webkit-scrollbar {
    display: none;
}

.page-header {
    margin: 0;
    font-size: var(--font-size-large);
    font-family: var(--font-family-bold);
    margin-bottom: 30px;
}

.row {
    display: flex;
    flex-direction: row;
}

.indexSegment {
    flex: 5;
}

.alertsSegment {
    margin-left: 30px;
    flex: 3;
}