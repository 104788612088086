.table-container {
    padding: 30px;
    padding-bottom: 15px;
}

.table {
    width: 100%;
    border-collapse: collapse;
    font-size: var(--font-size-tiny);
    white-space: nowrap;
}

.table-row:hover {
    background-color: var(--table-theme-grey);
}

.headerCell,
.dataCell {
    font-family: var(--font-family-regular);
    text-align: left;
    padding: 15px;
}

.dataCell {
    font-weight: normal;
}

.headerText {
    background-color: var(--table-theme-grey);
    white-space: nowrap;
}

.backing {
    width: 120px;
    height: 28px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 20px;
}

.noDataCell {
    text-align: center;
    padding: 20px;
    padding-bottom: 10px;
    font-size: var(--font-size-tiny);
    color: grey;
}