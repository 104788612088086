.alertsContainer {
    height: 437px;
    overflow-y: scroll;
    padding: 30px;
    padding-bottom: 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    font-family: var(--font-family-regular);
}

.alertsContainer::-webkit-scrollbar {
    display: none;
}

.timePeriod {
    margin-bottom: 20px;
}

.timePeriodTitle {
    margin: 0;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: var(--font-size-tiny);
    color: black;
}

.alert {
    padding: 15px;
    margin-bottom: 10px;
    font-size: var(--font-size-small);
}

.alertTitle {
    margin: 0;
    font-weight: bold;
    margin-bottom: 5px;
    color: black;
}

.alertDescription {
    margin: 0;
    color: black;
    font-size: var(--font-size-small);
}