.app-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.privacy-policy-container {
    margin: 20px;
    margin-top: 70px;
    padding: 20px;
    border: 1px solid var(--border-theme-grey);
    background-color: var(--internal-background-grey);
    border-radius: 5px;
}