.textfield {
    position: relative;
    width: 100%;
}

.textfield:not(.error) {
    margin-bottom: 20.5px;
}

.textfield label {
    position: absolute;
    left: 20px;
    top: 18px;
    font-size: var(--font-size-small);
    transition: all 0.2s ease;
    pointer-events: none;
}

.textfield:focus-within label,
.textfield.filled label {
    top: 0;
    left: 10px;
    font-size: var(--font-size-teenie);
    color: var(--theme-grey);
    font-family: var(--font-family-bold);
    padding-top: 10px;
}

.textfield input {
    width: calc(100% - 12px);
    padding: 0;
    padding-top: 26.5px;
    padding-bottom: 10px;
    padding-left: 10px;
    border: 1px solid var(--border-theme-grey);
    border-radius: 5px;
    background-color: white;
    font-size: var(--font-size-small);
    color: black;
}

.textfield.error input {
    border-color: red;
}

.error-text {
    font-size: var(--font-size-tiny);
    margin-top: 4px;
    color: red;
}

.error-text a {
    color: red;
    font-size: var(--font-size-tiny);
    text-decoration: none;
}

.error-text a:hover {
    text-decoration: underline;
}