.sidebar-button {
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--theme-grey);
    font-size: var(--font-size-tiny);
    cursor: pointer;
    position: relative;
}

.highlight-content {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    margin-left: 17px;
    margin-right: 20px;
    width: 100%;
}

.highlight-content.active {
    background-color: var(--side-bar-button-highlight);
}

.highlight-content.collapsed {
    margin-left: 12px;
    margin-right: 0;
    width: auto;
}

.button-content {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.icon-container {
    width: 18px;
    height: 18px;
    margin-right: 15px;
    margin-top: 4px;
    margin-bottom: 4px;
    flex-shrink: 0;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-container.collapsed {
    margin-right: 0px;
}

.icon-container .icon {
    width: 18px;
    height: 18px;
}

.label {
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    font-size: var(--font-size-tiny);
}

.active .label {
    color: var(--theme-blue);
}

.active .icon {
    fill: var(--theme-blue);
}

.active-indicator {
    width: 4px;
    height: 40px;
    background-color: transparent;
    border-radius: 10px;
    display: block;
    visibility: visible;
    opacity: 1;
}

.active-indicator.active {
    background-color: var(--theme-blue);
}

.active-indicator.collapsed {
    width: 2.5px;
}