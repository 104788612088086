.sliderContainer {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--border-theme-grey);
    border-radius: 5px;
    padding: 15px;
    position: relative;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.label {
    font-size: var(--font-size-teenie);
    color: var(--theme-grey);
    font-family: var(--font-family-bold);
}

.value {
    font-size: var(--font-size-teenie);
    color: var(--theme-grey);
    font-family: var(--font-family-bold);
}

.trackAndRange {
    position: relative;
    height: 10px;
}

.track {
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background-color: var(--radio-theme-blue);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
}

.filledTrack {
    height: 100%;
    background-color: var(--theme-blue);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.rangeInput {
    appearance: none;
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

.rangeInput::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border: none;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    margin-top: -5px;
    z-index: 3;
}

.rangeInput::-webkit-slider-thumb,
.rangeInput::-moz-range-thumb,
.rangeInput::-ms-thumb {
    appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    border: none;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.3);
    margin-top: -5px;
    z-index: 3;
}

.division {
    width: 4px;
    border-radius: 100%;
    height: 4px;
    background-color: white;
    position: absolute;
    top: 1px;
    transform: translateX(-50%);
    z-index: 2;
}

.sliderThumb {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 3;
    cursor: pointer;
}

.floatingLabel {
    position: absolute;
    width: 35px;
    text-align: center;
    top: 10px;
    background-color: var(--theme-blue);
    color: white;
    border-radius: 16px;
    font-size: var(--font-size-tiny);
    padding: 5px 10px;
    white-space: nowrap;
    font-weight: normal;
}