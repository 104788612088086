.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    height: 39px;
}

.brand {
    margin-right: auto;
    margin-left: 30px;
    max-height: 39px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.brand img {
    max-height: 25px;
    width: auto;
}

.close-button {
    margin-right: 30px;
    cursor: pointer;
    font-size: 24px;
}