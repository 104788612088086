.wrapper {
    padding: 20px;
}

.keyContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.risks {
    border: 1px solid var(--border-theme-grey);
    border-radius: 8px;
    padding: 10px;
    padding-bottom: 6px;
    padding-right: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.checkboxesContainer {
    display: flex;
    justify-content: space-between;
    width: 250px;
}

.container {
    position: relative;
    width: calc(100% - 40px);
    height: 350px;
    border: 1px solid var(--border-theme-grey);
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
    padding-bottom: 10px;
}

.grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(to bottom,
            #f0f0f0,
            #f0f0f0 1px,
            #fff 1px,
            #fff 10%);
}

.barContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 3%;
    height: 100%;
}

.bar {
    width: 100%;
    background-color: var(--theme-blue);
}

.averageLine,
.thresholdLine {
    position: absolute;
    left: 0;
    right: 0;
    height: 9px;
}

.thresholdLine {
    border-top: 4px dashed black;
}

.averageLine {
    border-top: 4px dashed var(--theme-blue);
}

.axisLabel {
    font-size: 10px;
    text-align: center;
    padding-top: 10px;
}