body {
  margin: 0;
  font-family: "Roc Grotesk Regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
button,
input,
textarea,
select {
  line-height: 1.0;
}

code {
  font-family: "Roc Grotesk Thin", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}

@font-face {
  font-family: "Roc Grotesk Heavy";
  src: local("Roc Grotesk Heavy"),
    url("/static/media/Kostic - Roc Grotesk Heavy.8d6c071b.otf") format("opentype");
}

@font-face {
  font-family: "Roc Grotesk Light";
  src: local("Roc Grotesk Light"),
    url("/static/media/Kostic - Roc Grotesk Light.65851d36.otf") format("opentype");
}

@font-face {
  font-family: "Roc Grotesk Medium";
  src: local("Roc Grotesk Medium"),
    url("/static/media/Kostic - Roc Grotesk Medium.c32d218c.otf") format("opentype");
}

@font-face {
  font-family: "Roc Grotesk Regular";
  src: local("Roc Grotesk Regular"),
    url("/static/media/Kostic - Roc Grotesk Regular.39c97760.otf") format("opentype");
}

@font-face {
  font-family: "Roc Grotesk Thin";
  src: local("Roc Grotesk Thin"),
    url("/static/media/Kostic - Roc Grotesk Thin.920fab6b.otf") format("opentype");
}
.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.hello-world {
  color: white;
}
:root {
    --radio-theme-blue: #E2E8F0;
    --side-bar-button-highlight: #fee5e5;
    --light-theme-blue: #F2F1F1;
    --bright-box-theme: #FE5131;
    --box-theme: #1D444B;
    --theme-blue: #7D1A24;
    --dark-theme-blue: #5E141A;
    --border-theme-grey: #E0E0E0;
    --table-theme-grey: #F6F8FA;
    --light-theme-grey: #D1D0D0;
    --theme-grey: #a3a3a3;
    --internal-background-grey: #F6F8FA;
    --discrete-theme-grey: #5B5B5B;
    --font-size-teenie: 10px;
    --font-size-tieny: 12px;
    --font-size-tiny: 14px;
    --font-size-small: 16px;
    --font-size-medium: 18px;
    --font-size-lardium: 20px;
    --font-size-large: 24px;
    --font-size-larger: 28px;
    --font-size-extra: 36px;
    --font-size-extra-large: 56px;
    --font-family-regular: "Roc Grotesk Regular", sans-serif;
    --font-family-thin: "Roc Grotesk Thin", sans-serif;
    --font-family-bold: "Roc Grotesk Medium", sans-serif;
}
.TopBar_top-bar__3CK60 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.TopBar_brand__P36xL {
    margin-right: auto;
    margin-left: 30px;
    max-height: 39px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.TopBar_brand__P36xL img {
    max-height: 25px;
    width: auto;
}

.TopBar_login-signup__7OdLy {
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.TopBar_login-button__2q2z9 {
    background-color: transparent;
    color: black;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
    transition: box-shadow 0.3s ease-in-out;
}

.TopBar_signup-button__16uEv {
    background-color: var(--theme-blue);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: var(--font-size-small);
    font-family: var(--font-family-bold);
}

.TopBar_signup-chevron__3c0Gh {
    margin-left: 5px;
}
.Introduction_introduction__1Er5g {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 62px;
    margin-bottom: auto;
    background-color: white;
}

.Introduction_blue-rectangle__1xo1X {
    margin-left: 30px;
    margin-right: 30px;
    width: 100%;
    border-radius: 28px;
    padding: 30px;
    color: white;
    text-align: center;
    background: var(--box-theme);

}

.Introduction_introduction-content__1-Y_g {
    text-align: center;
    margin-top: 60px;
}

.Introduction_intro-heading__2XGHw {
    font-size: var(--font-size-extra-large);
    color: white;
    margin-bottom: 20px;
    font-family: var(--font-family-regular);
}

.Introduction_intro-description__85D06 {
    font-size: var(--font-size-large);
    color: white;
    opacity: 0.75;
    margin-bottom: 90px;
    font-family: var(--font-family-regular);
}

.Introduction_button-content__2tszN {
    margin-bottom: 60px;
}

.Introduction_start-now-button__3c7xb {
    background-color: white;
    color: var(--theme-blue);
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
}

.Introduction_start-now-chevron__2Oerd {
    margin-left: 5px;
}
.WhatWeDo_what-we-do__1imVH {
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 50px;
    margin-bottom: auto;
}

.WhatWeDo_content-section__140Tb {
    width: 50%;
    padding: 30px;
}

.WhatWeDo_content-section__140Tb h1,
.WhatWeDo_content-section__140Tb h2,
.WhatWeDo_content-section__140Tb p {
    text-align: left;
    color: black;
}

.WhatWeDo_content-section__140Tb h1.WhatWeDo_section-title__1nAMx {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
}

.WhatWeDo_section-heading__6FJ3L {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: var(--font-size-larger);
    font-family: var(--font-family-regular);
}

.WhatWeDo_section-description__2NnZZ {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: var(--font-size-medium);
    font-family: var(--font-family-regular);
    line-height: 1.5;
}

.WhatWeDo_learn-more-button__38Pud {
    position: relative;
    margin-top: 30px;
    color: black;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-small);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.WhatWeDo_learn-more-button__38Pud .WhatWeDo_fa-icon__1yL-t {
    padding-left: 0;
}

.WhatWeDo_image-section__3ZBVD {
    width: 50%;
    padding: 30px;
}

.WhatWeDo_image-container__3CrKc {
    width: 100%;
    height: 100%;
    background-color: #0033ff17;
    border-radius: 12px;
}

.WhatWeDo_image-container__3CrKc img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
}

.WhatWeDo_underline__2xfYN {
    position: relative;
    margin-right: 15px;
}

.WhatWeDo_underline__2xfYN::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    height: 1px;
    background: black;
}
.HowItWorks_how-it-works__11IE_ {
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 50px;
    margin-bottom: auto;
}

.HowItWorks_section-top__3Q3Dm {
    display: flex;
    justify-content: space-between;
}

.HowItWorks_section-bottom__2JjuH {
    padding-left: 30px;
    padding-right: 30px;
}

.HowItWorks_content-section__3NhKU {
    width: 50%;
    padding: 30px;
}

.HowItWorks_content-section__3NhKU h1,
.HowItWorks_content-section__3NhKU h2,
.HowItWorks_content-section__3NhKU p {
    text-align: left;
    color: black;
}

.HowItWorks_content-section__3NhKU h1.HowItWorks_section-title__14JV7 {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
}

.HowItWorks_section-heading__kIwbj {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: var(--font-size-larger);
    font-family: var(--font-family-regular);
}

.HowItWorks_section-description__17wyf {
    margin-top: 15px;
    font-size: var(--font-size-medium);
    font-family: var(--font-family-regular);
    line-height: 1.5;
}

.HowItWorks_buttons-container__lde1l {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 45px;
    justify-content: flex-start;
}

.HowItWorks_sponsors-button__1JoJC,
.HowItWorks_investors-button__zLCSO {
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-small);
}

.HowItWorks_sponsors-button__1JoJC.HowItWorks_selected__1UPqJ,
.HowItWorks_investors-button__zLCSO.HowItWorks_selected__1UPqJ {
    background-color: var(--theme-blue);
    color: white;
    border: none;
}

.HowItWorks_sponsors-button__1JoJC:not(.HowItWorks_selected__1UPqJ),
.HowItWorks_investors-button__zLCSO:not(.HowItWorks_selected__1UPqJ) {
    background-color: white;
    color: black;
    border: 1px solid black;
}

.HowItWorks_right-content-section__1DYpd {
    width: 50%;
    padding-top: 68px;
    position: relative;
}

.HowItWorks_navigation-container__coTl_ {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.HowItWorks_chevron-container__WE55u {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}

.HowItWorks_chevron-button__1Xp9Y {
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HowItWorks_chevron-button__1Xp9Y.HowItWorks_disabled__1GByO {
    background-color: transparent;
    color: darkgray;
    cursor: default;
}

.HowItWorks_how-it-works-image-container__2cJAQ {
    display: flex;
    justify-content: center;
    align-items: center;
}

.HowItWorks_how-it-works-image__3pJIq {
    width: 100%;
    border: 1px solid var(--light-theme-grey);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.HowItWorks_image-overlay__1859I {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0033ff17;
    -webkit-filter: blur(10px);
            filter: blur(10px);
    transition: background-color 0.5s ease;
}

.HowItWorks_how-it-works-image__3pJIq.HowItWorks_image-loaded__fqcqr .HowItWorks_image-overlay__1859I {
    background-color: transparent;
}

.HowItWorks_how-it-works-image__3pJIq img {
    width: 100%;
    height: auto;
    display: block;
}

.HowItWorks_text-container__qbxpA {
    position: absolute;
    white-space: nowrap;
    margin-top: 20px;
}

.HowItWorks_text-line__2UsNb {
    display: flex;
    align-items: center;
    font-family: var(--font-family-bold);
    cursor: pointer;
    transition: color 0.3s ease;
    margin-bottom: 10px;
    margin-top: 0;
    white-space: nowrap;
    overflow: hidden;
}

.HowItWorks_focused__U-tEX {
    color: black;
}

.HowItWorks_text-line__2UsNb:not(.HowItWorks_focused__U-tEX) {
    padding-top: 6px;
    color: var(--light-theme-grey);
}

.HowItWorks_number__18JfG {
    font-size: var(--font-size-larger);
    margin-right: 15px;
}

.HowItWorks_text__2bKnj {
    font-size: var(--font-size-medium);
}

.HowItWorks_focused__U-tEX .HowItWorks_number__18JfG {
    font-size: var(--font-size-extra);
}

.HowItWorks_focused__U-tEX .HowItWorks_text__2bKnj {
    font-size: var(--font-size-larger);
}
.UseCase_use-case__3HV-x {
    width: 90%;
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 110px;
    margin-bottom: auto;
}

.UseCase_header__SqwMb {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.UseCase_use-case-title__17BKl {
    color: var(--dark-theme-blue);
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
}

.UseCase_tranquil-in-action__2b_Cg {
    font-size: var(--font-size-larger);
    margin-top: 0;
    margin-bottom: 10px;
}

.UseCase_content__1tpj5 {
    border: 1px solid var(--light-theme-grey);
    border-radius: 20px;
    background-color: white;
    padding-top: 8%;
    padding-left: 8%;
    padding-right: 8%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.UseCase_left-content__g1ECR {
    flex-basis: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.UseCase_meet-joaquim__16xiQ {
    font-size: var(--font-size-extra);
    font-family: var(--font-family-regular);
    margin-bottom: 15px;
    margin-top: 0;
}

.UseCase_description__16rhj {
    font-size: var(--font-size-medium);
    line-height: 1.5;
}

.UseCase_right-content__275Ug {
    flex-basis: 33.33%;
}

.UseCase_image-container__1K80C {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.UseCase_image-container__1K80C img {
    border-radius: 12px;
    height: 100%;
    width: 100%;
}

.UseCase_joaquim-image__3KIDd {
    width: 100%;
    height: auto;
}

.UseCase_read-more-container__1iNdn {
    width: 100%;
    text-align: center;
    padding-top: 8%;
    padding-bottom: 3%;
}

.UseCase_read-more-button__3c4xc {
    background-color: transparent;
    color: black;
    border: none;
    cursor: pointer;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-small);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.UseCase_expand-icon__jphUt {
    margin-left: 8px;
    font-size: 14px;
}
.OurClients_our-clients-main__3sAz1 {
    margin-top: 140px;
    align-items: flex-start;
}

.OurClients_our-clients-top__AijYq {
    width: 80%;
    margin: auto;
}

.OurClients_section-title__2uzhk {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
}

.OurClients_logo-container__38v1b {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    grid-gap: 40px;
    gap: 40px;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 30px;
}

.OurClients_logo-item__2352o {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.OurClients_logo-item__2352o img {
    max-height: 40px;
    width: auto;
}

.OurClients_testimonial-full-container__-Qhf7 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-top: 60px;
}

.OurClients_testimonial-container__3WDUO {
    display: flex;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
    width: 80%;
    margin: 0 auto;
}

.OurClients_testimonial__2yODO {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 30px;
    width: 26%;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.OurClients_testimonial-text__3WgwG {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.OurClients_testimonial-author__1nzDE,
.OurClients_testimonial-company__19Kui {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-regular);
}

.OurClients_testimonial-description__1HQUr {
    font-size: var(--font-size-medium);
    font-family: var(--font-family-regular);
    text-align: left;
    line-height: 1.5;
    margin-top: 15px;
}

.OurClients_story-button__2gThp {
    background-color: var(--light-theme-blue);
    color: var(--theme-blue);
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-small);
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: left;
    align-self: flex-start;
}

.OurClients_story-button__2gThp:hover {
    background-color: var(--theme-blue);
    color: white;
}

.OurClients_testimonial-top__fAR4V {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.OurClients_testimonial-description__1HQUr,
.OurClients_story-button__2gThp {
    text-align: left;
}

.OurClients_chevron__62NDh {
    padding: 0 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.OurClients_chevron__62NDh.OurClients_left__U7_bp {
    left: calc(5% - 10px);
}

.OurClients_chevron__62NDh.OurClients_right__Mz3wF {
    right: calc(5% - 10px);
}

.OurClients_chevron__62NDh.OurClients_disabled__3VxR- {
    color: #ccc;
    cursor: default;
}
.BottomBar_bottom-bar__1xJYY {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    background-color: var(--box-theme);
    color: white;
    font-family: var(--font-family-regular);
    border-radius: 15px;
    width: calc(100% - 90px);
    margin: 180px 45px 45px 45px;
    box-sizing: border-box;
}

.BottomBar_links-container__2ReWw {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 60px;
    grid-gap: 50px;
    gap: 50px;
}

.BottomBar_links-column__2FDIz {
    display: flex;
    flex-direction: column;
}

.BottomBar_links-column__2FDIz h1 {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    cursor: default;
    color: white;
}

.BottomBar_links-column__2FDIz a {
    font-size: var(--font-size-tiny);
    text-decoration: none;
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
}

.BottomBar_social-media-icons__1x22f {
    display: flex;
    justify-content: space-between;
    width: 240px;
    font-size: 1.7em;
}

.BottomBar_social-media-icon__2iwUU {
    cursor: pointer;
}

.BottomBar_line__1-FZV {
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    margin: 30px 0px;
}

.BottomBar_brand-container__Mg7XD {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.BottomBar_brand-name__zU3F0 {
    font-size: var(--font-size-small);
    font-family: var(--font-family-bold);
    color: white;
    margin: 0;
}
.TopBar_top-bar__1eY0R {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.TopBar_brand__1ST5D {
    margin-right: auto;
    margin-left: 30px;
    max-height: 39px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.TopBar_brand__1ST5D img {
    max-height: 25px;
    width: auto;
}

.TopBar_login-signup__2b-u3 {
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.TopBar_question-text__359N2 {
    background-color: transparent;
    color: black;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
    font-family: var(--font-family-regular);
    transition: box-shadow 0.3s ease-in-out;
    pointer-events: none;
}

.TopBar_signup-button__2cdiL {
    background-color: var(--theme-blue);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
}

.TopBar_signup-chevron__K9_WU {
    margin-left: 5px;
}
.SignInMain_signin-main__1DgG6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 70vh;
    margin-top: 15vh;
    margin-bottom: 15vh;
}

.SignInMain_signin-title-container__1x-Ov {
    text-align: center;
}

.SignInMain_signin-tiny-text__CGtsh {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
}

.SignInMain_signin-welcome-text__2aUWc {
    font-size: var(--font-size-large);
    font-weight: var(--font-family-thin);
    color: black;
    margin: 10px 0;
}

.SignInMain_signin-button__1rDFo {
    margin-top: 30px;
    padding: 10px 20px;
    background-color: var(--theme-blue);
    color: white;
    border: none;
    border-radius: 20px;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
    cursor: pointer;
}

.SignInMain_signin-button__1rDFo.SignInMain_disabled__1Gz8b {
    background-color: var(--light-theme-grey);
    cursor: not-allowed;
}

.SignInMain_textfield-container__1OaEE {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    width: 360px;
}

.SignInMain_loading-container__38eJ4 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 30px;
}

.SignInMain_loading-indicator__20AmV {
    border: 5px solid var(--internal-background-grey);
    border-top: 5px solid var(--theme-blue);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: SignInMain_spin__dgR_- 1s linear infinite;
            animation: SignInMain_spin__dgR_- 1s linear infinite;
}

@-webkit-keyframes SignInMain_spin__dgR_- {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes SignInMain_spin__dgR_- {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.Textfield_textfield__18J0B {
    position: relative;
    width: 100%;
}

.Textfield_textfield__18J0B:not(.Textfield_error__cmYL2) {
    margin-bottom: 20.5px;
}

.Textfield_textfield__18J0B label {
    position: absolute;
    left: 20px;
    top: 18px;
    font-size: var(--font-size-small);
    transition: all 0.2s ease;
    pointer-events: none;
}

.Textfield_textfield__18J0B:focus-within label,
.Textfield_textfield__18J0B.Textfield_filled__KHbWk label {
    top: 0;
    left: 10px;
    font-size: var(--font-size-teenie);
    color: var(--theme-grey);
    font-family: var(--font-family-bold);
    padding-top: 10px;
}

.Textfield_textfield__18J0B input {
    width: calc(100% - 12px);
    padding: 0;
    padding-top: 26.5px;
    padding-bottom: 10px;
    padding-left: 10px;
    border: 1px solid var(--border-theme-grey);
    border-radius: 5px;
    background-color: white;
    font-size: var(--font-size-small);
    color: black;
}

.Textfield_textfield__18J0B.Textfield_error__cmYL2 input {
    border-color: red;
}

.Textfield_error-text__1iYBq {
    font-size: var(--font-size-tiny);
    margin-top: 4px;
    color: red;
}

.Textfield_error-text__1iYBq a {
    color: red;
    font-size: var(--font-size-tiny);
    text-decoration: none;
}

.Textfield_error-text__1iYBq a:hover {
    text-decoration: underline;
}
.TopBar_top-bar___f70U {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.TopBar_brand__1BYK4 {
    margin-right: auto;
    margin-left: 30px;
    max-height: 39px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.TopBar_brand__1BYK4 img {
    max-height: 25px;
    width: auto;
}

.TopBar_login-signup__Z4FB7 {
    display: flex;
    align-items: center;
    margin-right: 30px;
    font-family: var(--font-family-bold);
}

.TopBar_question-text__Jtxse {
    background-color: transparent;
    color: black;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
    transition: box-shadow 0.3s ease-in-out;
    pointer-events: none;
}

.TopBar_signup-button__1gu3g {
    background-color: var(--theme-blue);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
}

.TopBar_signup-chevron__1nQY- {
    margin-left: 5px;
}

.TopBar_progress-bar-sizer__iVQTY {
    height: 34.5px;
    align-items: center;
    display: flex;
}

.TopBar_progress-bar__2REeb {
    display: flex;
    align-items: center;
}

.TopBar_progress-circle__HUfd9 {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: white;
    margin: 0;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TopBar_progress-circle__HUfd9.TopBar_active__1GEcv {
    border: 4px solid var(--theme-blue);
}

.TopBar_progress-circle__HUfd9:not(.TopBar_active__1GEcv) {
    border: 4px solid var(--radio-theme-blue);
}
.main_main-container__29_48 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 75vh;
    margin-top: 15vh;
    margin-bottom: 10vh;
}

.main_page-header-text__ET9JL {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.main_page-header-tiny-text__1Qu7V {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
    margin-bottom: 10px;
}

.main_page-header-descriptor-text__xyeJd {
    font-size: var(--font-size-large);
    font-weight: var(--font-family-thin);
    color: black;
    margin: 0;
}

.main_cards-container__2sMzQ {
    display: flex;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
}

.main_card__k5ZNu {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 30px;
    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.main_shield-icon__15OcS {
    font-size: 2.5rem;
    color: black;
    height: 56px;
}

.main_chart-icon__1uKuX {
    font-size: 2.5rem;
    color: black;
    padding-top: 8px;
    height: 48px;
}

.main_card-description__xSh7V {
    font-size: var(--font-size-small);
    font-family: var(--font-family-regular);
    text-align: center;
    line-height: 1.5;
    margin-top: 30px;
    margin-bottom: 30px;
}

.main_story-button__WZSL6 {
    background-color: var(--light-theme-blue);
    color: var(--theme-blue);
    border: none;
    border-radius: 20px;
    padding: 10px 40px;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-small);
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: center;
}

.main_story-button__WZSL6:hover {
    background-color: var(--theme-blue);
    color: white;
}

@media (max-width: 768px) {
    .main_cards-container__2sMzQ {
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
    }
}
.Dropdown_dropdown__1GpQp {
    position: relative;
}

.Dropdown_dropdownHeader__zomkc {
    width: calc(100% - 30px);
    padding: 10px;
    padding-right: 20px;
    border: 1px solid var(--border-theme-grey);
    border-radius: 5px;
    background-color: white;
    font-size: var(--font-size-small);
    color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Dropdown_dropdownLabel__1acsp {
    font-size: var(--font-size-teenie);
    color: var(--theme-grey);
    font-family: var(--font-family-bold);
}

.Dropdown_dropdownSelected__WfWxH {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4px;
}

.Dropdown_chevronIcon__1HTAy {
    margin-left: 8px;
}

.Dropdown_dropdownOptions__1kfuN {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid var(--border-theme-grey);
    border-radius: 5px;
}

.Dropdown_dropdownOption__27uP8 {
    padding: 10px;
    transition: background-color 0.2s ease;
    cursor: pointer;
}

.Dropdown_dropdownOption__27uP8:hover {
    background-color: var(--light-theme-blue);
    color: var(--dark-theme-blue);
}

.Dropdown_selectedOption__3YO-U {
    background-color: var(--light-theme-blue);
    color: var(--dark-theme-blue);
}
.page1_input-fields__3EGhG {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.page1_radio-buttons__15OS5 {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
}

.page1_text-fields-container__sBZlm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 580px;
}

.page1_text-field-sizer__EhGTM {
    width: 275px;
}

.page1_drop-down-sizer__34KEp {
    width: 580px;
    margin-top: 9.5px;
}
.path1_main-container__3PBYw {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 75vh;
    margin-top: 15vh;
    margin-bottom: 10vh;
}

.path1_page-header-text__3k9Y2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.path1_page-header-tiny-text__2vraJ {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
    margin-bottom: 10px;
}

.path1_page-header-descriptor-text__1Ymud {
    font-size: var(--font-size-large);
    font-weight: var(--font-family-thin);
    color: black;
    margin: 0;
}

.path1_button-group__2jpJT {
    display: flex;
    flex-direction: column;
    width: 150px;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
}

.path1_next-button__H_ddY {
    background-color: var(--theme-blue);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 40px;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.path1_back-button__3ollS {
    background-color: transparent;
    color: black;
    border: none;
    border-radius: 20px;
    padding: 10px;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-tiny);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.path1_chevron-icon__3Yxg_ {
    margin-right: 5px;
}

.path1_next-button__H_ddY.path1_disabled__3_qOh,
.path1_next-button__H_ddY:disabled {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed;
}

.path1_back-button__3ollS:hover:not(.path1_disabled__3_qOh) {
    color: var(--discrete-theme-grey);
}

.path1_next-button__H_ddY:hover:not(.path1_disabled__3_qOh) {
    background-color: var(--light-theme-blue);
    color: var(--theme-blue);
}
.RadioButtons_radioButtonContainer__1gkwN {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}

.RadioButtons_horizontal__2-uJk {
    flex-direction: row;
}

.RadioButtons_radioButton__FPpWs {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.RadioButtons_radioButton__FPpWs input[type="radio"] {
    margin-right: 6px;
}

.RadioButtons_radioButton__FPpWs input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 3px solid var(--radio-theme-blue);
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    background-color: white;
}

.RadioButtons_radioButton__FPpWs input[type="radio"]:checked {
    background-color: white;
    border: 5.5px solid var(--theme-blue);
}

.RadioButtons_radioButton__FPpWs input[type="radio"]:checked::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
}
.page2_selectables-sizer__snTR2 {
    width: 50vw;
}
.Selectables_selectables-container__11I_x {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
    justify-content: center;
    margin: 0 8px;
}

.Selectables_selectable-button__1J9AG {
    background-color: white;
    border: 1px solid var(--border-theme-grey);
    border-radius: 8px;
    padding: 10px;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-tiny);
    cursor: pointer;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.Selectables_selectable-button__1J9AG.Selectables_selected__1YPHf {
    background-color: var(--theme-blue);
    color: white;
}

.Selectables_check-icon__2xF9z {
    color: white;
}
.page3_checkboxes__3U_4N {
    margin-top: 15px;
}

.page3_checkboxes__3U_4N a {
    color: var(--theme-blue);
}

.page3_checkboxes__3U_4N a:visited {
    color: var(--dark-theme-blue);
}

.page3_loading-container__3VEXQ {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 30px;
}

.page3_loading-indicator__1HaA9 {
    border: 5px solid var(--internal-background-grey);
    border-top: 5px solid var(--theme-blue);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: page3_spin__2hcIr 1s linear infinite;
            animation: page3_spin__2hcIr 1s linear infinite;
}

@-webkit-keyframes page3_spin__2hcIr {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes page3_spin__2hcIr {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.Checkboxes_checkboxesContainer__1bFh7 {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}

.Checkboxes_checkboxButton__2FYkX {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    position: relative;
}

.Checkboxes_checkboxButton__2FYkX input[type='checkbox'] {
    position: absolute;
    width: 20px;
    height: 20px;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
            appearance: none;
    background-color: white;
    border: 2px solid var(--radio-theme-blue);
    border-radius: 4px;
    margin: 0;
}

.Checkboxes_checkboxButton__2FYkX input[type='checkbox']:checked+.Checkboxes_checkboxIconWrapper__1dKci {
    border-radius: 4px;
}

.Checkboxes_checked__Bsev3 {
    background-color: var(--theme-blue);
    border-color: var(--theme-blue);
}


.Checkboxes_checkboxButton__2FYkX .Checkboxes_checkboxIconWrapper__1dKci {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 15px;
}

.Checkboxes_checkboxButton__2FYkX .Checkboxes_check__1Lo3o {
    color: white;
    font-size: 14px;
}
.path2_main-container__11I8U {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 75vh;
    margin-top: 15vh;
    margin-bottom: 10vh;
}

.path2_page-header-text__2HFFD {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.path2_page-header-tiny-text__3zhBC {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
    margin-bottom: 10px;
}

.path2_page-header-descriptor-text__pT0jp {
    font-size: var(--font-size-large);
    font-weight: var(--font-family-thin);
    color: black;
    margin: 0;
}

.path2_button-group__1R1w2 {
    display: flex;
    flex-direction: column;
    width: 150px;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
}

.path2_next-button__ywEcq {
    background-color: var(--theme-blue);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 40px;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.path2_back-button__zuA6G {
    background-color: transparent;
    color: black;
    border: none;
    border-radius: 20px;
    padding: 10px;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-tiny);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.path2_chevron-icon__2rCTI {
    margin-right: 5px;
}

.path2_next-button__ywEcq.path2_disabled__2CqXj,
.path2_next-button__ywEcq:disabled {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed;
}

.path2_back-button__zuA6G:hover:not(.path2_disabled__2CqXj) {
    color: var(--discrete-theme-grey);
}

.path2_next-button__ywEcq:hover:not(.path2_disabled__2CqXj) {
    background-color: var(--light-theme-blue);
    color: var(--theme-blue);
}
.page1_input-fields__1Tl7H {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.page1_text-fields-container__NHD5- {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 580px;
}

.page1_text-field-sizer__3bNUM {
    width: 275px;
}

.page1_text-field-sizer-large__1jH8B {
    margin-top: 10px;
    width: 580px;
}
.page2_input-fields__eHUVB {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.page2_page-header-text__16Imc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.page2_page-header-tiny-text__1nq7L {
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    color: var(--dark-theme-blue);
    margin-bottom: 10px;
}

.page2_page-header-descriptor-text__3e6b3 {
    font-size: var(--font-size-large);
    font-weight: var(--font-family-thin);
    color: black;
    margin: 0;
}

.page2_image-container__22dkS {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.page2_image__iBIT- {
    width: 580px;
    border-radius: 8px;
    overflow: hidden;
}

.page2_text-fields-container__etWoM {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 580px;
}

.page2_text-field-sizer-large__tX92D {
    margin-top: 10px;
    width: 580px;
}
.page3_squares-container__dI5B- {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.page3_square-container__1FTEw {
    position: relative;
}

.page3_square__2s_ua {
    width: 150px;
    height: 150px;
    border: 1px solid var(--border-theme-grey);
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 15px;
}

.page3_square-content__2TI8S {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page3_icon__3QQEB {
    width: 60px;
    height: 60px;
    color: black;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page3_title__1hApY {
    margin-top: 5px;
    color: black;
}

.page3_checkbox__3pPMB {
    position: absolute;
    top: calc(100%);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.page3_unavailable__3Qtdb {
    opacity: 0.5;
    cursor: not-allowed;
}

.page3_unavailable-text__10nXp {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: grey;
}

.page3_button-group__1rrua {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.page3_checkboxesContainer__3nQlG {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}

.page3_checkboxButton__3ckyV {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    position: relative;
}

.page3_checkboxButton__3ckyV input[type='checkbox'] {
    position: absolute;
    width: 20px;
    height: 20px;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
            appearance: none;
    background-color: white;
    border: 2px solid var(--radio-theme-blue);
    border-radius: 4px;
    margin: 0;
}

.page3_checkboxButton__3ckyV input[type='checkbox']:checked+.page3_checkboxIconWrapper__3PSRq {
    background-color: var(--theme-blue);
    border-color: var(--theme-blue);
    border-radius: 4px;
}

.page3_checkboxButton__3ckyV .page3_checkboxIconWrapper__3PSRq {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.page3_checkboxButton__3ckyV .page3_check__3m1qj {
    color: white;
    font-size: 14px;
}
.page4_slider-container__3d_jx {
    margin-top: 10px;
    width: 580px;
}

.page4_text-fields-container__1QvXF {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
    width: 580px;
}

.page4_text-field-sizer__2ypSY {
    width: 275px;
}
.Slider_sliderContainer__-54lE {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--border-theme-grey);
    border-radius: 5px;
    padding: 15px;
    position: relative;
}

.Slider_header__ad1TE {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.Slider_label__-r58Q {
    font-size: var(--font-size-teenie);
    color: var(--theme-grey);
    font-family: var(--font-family-bold);
}

.Slider_value__k4o_w {
    font-size: var(--font-size-teenie);
    color: var(--theme-grey);
    font-family: var(--font-family-bold);
}

.Slider_trackAndRange__1BpjQ {
    position: relative;
    height: 10px;
}

.Slider_track__31t5f {
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background-color: var(--radio-theme-blue);
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    overflow: hidden;
}

.Slider_filledTrack__28OQI {
    height: 100%;
    background-color: var(--theme-blue);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.Slider_rangeInput__2UJCY {
    -webkit-appearance: none;
            appearance: none;
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 2;
}

.Slider_rangeInput__2UJCY::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border: none;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    margin-top: -5px;
    z-index: 3;
}

.Slider_rangeInput__2UJCY::-webkit-slider-thumb,
.Slider_rangeInput__2UJCY::-moz-range-thumb,
.Slider_rangeInput__2UJCY::-ms-thumb {
    -webkit-appearance: none;
            appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    border: none;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.3);
    margin-top: -5px;
    z-index: 3;
}

.Slider_division__TkUxo {
    width: 4px;
    border-radius: 100%;
    height: 4px;
    background-color: white;
    position: absolute;
    top: 1px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 2;
}

.Slider_sliderThumb__1oBQx {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 3;
    cursor: pointer;
}

.Slider_floatingLabel__JNJ5H {
    position: absolute;
    width: 35px;
    text-align: center;
    top: 10px;
    background-color: var(--theme-blue);
    color: white;
    border-radius: 16px;
    font-size: var(--font-size-tiny);
    padding: 5px 10px;
    white-space: nowrap;
    font-weight: normal;
}
.page5_squares-container-wrapper__3Te0F {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page5_squares-container__3769y {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    grid-gap: 30px;
    gap: 30px;
    margin-bottom: 30px;
}

.page5_square-container__3VVJF {
    position: relative;
}

.page5_square__Ndwj2 {
    width: 150px;
    height: 150px;
    border: 1px solid var(--border-theme-grey);
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.page5_square__Ndwj2.page5_highlighted__Hr0hc {
    border: 1px solid var(--theme-blue);
}

.page5_square-content__ocsJV {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page5_icon__17omS {
    width: 60px;
    height: 60px;
    color: black;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page5_title__1KFhA {
    margin-top: 5px;
    color: black;
}

.page5_checkbox__e9Lm0 {
    position: absolute;
    top: calc(100%);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.page5_unavailable__HOlJX {
    opacity: 0.5;
    cursor: not-allowed;
}

.page5_unavailable-text__38jzJ {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: grey;
}

.page5_button-group__mkYht {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.page5_slider-container__2LxmI {
    width: 580px;
}
.page6_checkboxes__rreAY {
    margin-top: 15px;
}

.page6_checkboxes__rreAY a {
    color: var(--theme-blue);
}

.page6_checkboxes__rreAY a:visited {
    color: var(--dark-theme-blue);
}

.page6_loading-container__23crm {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 30px;
}

.page6_loading-indicator__3hxe- {
    border: 5px solid var(--internal-background-grey);
    border-top: 5px solid var(--theme-blue);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: page6_spin__3OUW9 1s linear infinite;
            animation: page6_spin__3OUW9 1s linear infinite;
}

@-webkit-keyframes page6_spin__3OUW9 {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes page6_spin__3OUW9 {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.PrivacyPolicy_app-container__2LIe_ {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.PrivacyPolicy_privacy-policy-container__1Iaiz {
    margin: 20px;
    margin-top: 70px;
    padding: 20px;
    border: 1px solid var(--border-theme-grey);
    background-color: var(--internal-background-grey);
    border-radius: 5px;
}
.TopBar_top-bar__3noD2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    height: 39px;
}

.TopBar_brand__2N6nk {
    margin-right: auto;
    margin-left: 30px;
    max-height: 39px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.TopBar_brand__2N6nk img {
    max-height: 25px;
    width: auto;
}

.TopBar_close-button__14N50 {
    margin-right: 30px;
    cursor: pointer;
    font-size: 24px;
}
.TermsAndConditions_app-container__2aDls {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.TermsAndConditions_terms-container__jj8J1 {
    margin: 20px;
    margin-top: 70px;
    padding: 20px;
    border: 1px solid var(--border-theme-grey);
    background-color: var(--internal-background-grey);
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
/* TopBar styles */
.TopBar_top-bar__3MvVt {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.TopBar_logo__2-NPG {
    width: 198.03px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TopBar_logo__2-NPG.TopBar_collapsed__2myW2 {
    width: 78px;
}

.TopBar_brand__Wh2zZ {
    margin-right: auto;
    margin-left: 30px;
    max-height: 39px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.TopBar_brand__Wh2zZ img {
    max-height: 22px;
    width: auto;
}

.TopBar_main-panel__IRi3X {
    width: calc(100vw - 198.03px);
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: width 0.5s ease;
}

.TopBar_main-panel__IRi3X.TopBar_collapsed__2myW2 {
    width: calc(100vw - 78px);
}

.TopBar_search__3tXDP {
    margin-left: 30px;
    width: 300px;
    height: 50%;
    padding-left: 10px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: var(--internal-background-grey);
}

.TopBar_search-bar__3Fa4U {
    display: flex;
    align-items: center;
}

.TopBar_search-icon__3PTJ2 {
    width: 15px;
    height: 15px;
    margin-right: 0px;
}

.TopBar_search-input__20l-w {
    flex-grow: 1;
    padding: 8px;
    border: none;
    background: transparent;
    font-size: var(--font-size-tiny);
    color: black;
    outline: none;
}

.TopBar_actions__2Xxho {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 30px;
}

.TopBar_avatar-circle__1DnVe {
    width: 32px;
    height: 32px;
    background-color: var(--internal-background-grey);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;
}

.TopBar_avatar-icon__UpSTR {
    width: 18px;
    height: 18px;
}

.TopBar_avatar-profile__3EgYe {
    width: 42px;
    height: 42px;
    background-color: var(--internal-background-grey);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;
}

.TopBar_profile-pic__fbQg8 {
    width: 42px;
    height: 42px;
    border-radius: 50%;
}

.TopBar_profile-name__34PAP {
    display: flex;
    align-items: center;
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    cursor: pointer;
    margin-left: 15px;
}

.TopBar_chevron-down__2vNiK {
    margin-left: 10px;
}
.SideBar_sidebar__jMIGA {
    width: 220px;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    transition: width 0.5s ease;
}

.SideBar_sidebar__jMIGA.SideBar_collapsed__2ytRY {
    width: 80px;
}

.SideBar_menu-group__3Ah0l {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.SideBar_menu-heading__2KdFE {
    font-size: var(--font-size-teenie);
    font-family: var(--font-family-bold);
    color: var(--theme-grey);
    margin-bottom: 10px;
    margin-left: 36px;
}

.SideBar_menu-item__1JO1D {
    display: flex;
    align-items: center;
    padding: 10px;
    color: var(--theme-grey);
    font-size: var(--font-size-tiny);
    text-decoration: none;
}

.SideBar_menu-icon__ZeOJW {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.SideBar_menu-item__1JO1D:hover {
    color: var(--theme-blue);
}

.SideBar_selected__1P0sc {
    color: var(--theme-blue);
    font-size: var(--font-size-tiny);
}
.SideBarButton_sidebar-button__2PgPX {
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--theme-grey);
    font-size: var(--font-size-tiny);
    cursor: pointer;
    position: relative;
}

.SideBarButton_highlight-content__3pD8C {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    margin-left: 17px;
    margin-right: 20px;
    width: 100%;
}

.SideBarButton_highlight-content__3pD8C.SideBarButton_active__17AZb {
    background-color: var(--side-bar-button-highlight);
}

.SideBarButton_highlight-content__3pD8C.SideBarButton_collapsed__1ixhi {
    margin-left: 12px;
    margin-right: 0;
    width: auto;
}

.SideBarButton_button-content__1tjz9 {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.SideBarButton_icon-container__1_wVR {
    width: 18px;
    height: 18px;
    margin-right: 15px;
    margin-top: 4px;
    margin-bottom: 4px;
    flex-shrink: 0;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SideBarButton_icon-container__1_wVR.SideBarButton_collapsed__1ixhi {
    margin-right: 0px;
}

.SideBarButton_icon-container__1_wVR .SideBarButton_icon__2J2mf {
    width: 18px;
    height: 18px;
}

.SideBarButton_label__1H6Df {
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    font-size: var(--font-size-tiny);
}

.SideBarButton_active__17AZb .SideBarButton_label__1H6Df {
    color: var(--theme-blue);
}

.SideBarButton_active__17AZb .SideBarButton_icon__2J2mf {
    fill: var(--theme-blue);
}

.SideBarButton_active-indicator__pnkR7 {
    width: 4px;
    height: 40px;
    background-color: transparent;
    border-radius: 10px;
    display: block;
    visibility: visible;
    opacity: 1;
}

.SideBarButton_active-indicator__pnkR7.SideBarButton_active__17AZb {
    background-color: var(--theme-blue);
}

.SideBarButton_active-indicator__pnkR7.SideBarButton_collapsed__1ixhi {
    width: 2.5px;
}
.internal-app {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.internal-body {
    position: fixed;
    top: 64px;
    left: 0;
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--internal-background-grey);
}
.Dashboard_body__3Lyow {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: var(--internal-background-grey);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Dashboard_body__3Lyow::-webkit-scrollbar {
    display: none;
}

.Dashboard_page-header__3P4n3 {
    margin: 0;
    font-size: var(--font-size-large);
    font-family: var(--font-family-bold);
    margin-bottom: 30px;
}
.Segment_segment-title__HReX2 {
    color: var(--theme-grey);
    font-size: var(--font-size-tieny);
    font-family: var(--font-family-bold);
    margin-bottom: 15px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Segment_body__2qh0E::-webkit-scrollbar {
    display: none;
}

.Segment_segment-body__3HZBO {
    width: 100%;
    display: inline-block;
    background-color: white;
    margin-bottom: 30px;
    border-radius: 4px;
}
.Overview_container__3YiMp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.Overview_stat-card__A3qmk {
    flex: 1 1;
    display: flex;
    align-items: center;
    padding: 30px;
    position: relative;
}

.Overview_circle__30XsU {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    background-color: var(--theme-blue);
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Overview_icon__TuHMI {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.Overview_content__11K74 {
    display: flex;
    flex-direction: column;
}

.Overview_value__2bQAH {
    font-size: var(--font-size-lardium);
    font-family: var(--font-family-bold);
    color: black;
}

.Overview_descriptor__2pqWn {
    font-size: var(--font-size-tiny);
    color: var(--theme-grey);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Overview_vertical-line__2KGKu {
    width: 1px;
    height: 46px;
    background-color: var(--light-theme-grey);
    margin-right: 30px;
}
.Table_table-container__hEWpE {
    padding: 30px;
    padding-bottom: 15px;
}

.Table_table__35jq6 {
    width: 100%;
    border-collapse: collapse;
    font-size: var(--font-size-tiny);
    white-space: nowrap;
}

.Table_table-row__2HUyo:hover {
    background-color: var(--table-theme-grey);
}

.Table_headerCell__2Plu-,
.Table_dataCell__uTnJy {
    font-family: var(--font-family-regular);
    text-align: left;
    padding: 15px;
}

.Table_dataCell__uTnJy {
    font-weight: normal;
}

.Table_headerText__2Ogv0 {
    background-color: var(--table-theme-grey);
    white-space: nowrap;
}

.Table_backing__2Nv9O {
    width: 120px;
    height: 28px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 20px;
}

.Table_noDataCell__2LTct {
    text-align: center;
    padding: 20px;
    padding-bottom: 10px;
    font-size: var(--font-size-tiny);
    color: grey;
}
.Contracts_body__198gK {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: var(--internal-background-grey);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Contracts_body__198gK::-webkit-scrollbar {
    display: none;
}

.Contracts_page-header__3Ckw1 {
    margin: 0;
    font-size: var(--font-size-large);
    font-family: var(--font-family-bold);
    margin-bottom: 30px;
}
.ContractDetails_body__TFngS {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: var(--internal-background-grey);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.ContractDetails_body__TFngS::-webkit-scrollbar {
    display: none;
}

.ContractDetails_page-header__3y215 {
    margin: 0;
    font-size: var(--font-size-large);
    font-family: var(--font-family-bold);
    margin-bottom: 30px;
}

.ContractDetails_row__2PMFU {
    display: flex;
    flex-direction: row;
}

.ContractDetails_indexSegment__3NMQ4 {
    flex: 5 1;
}

.ContractDetails_alertsSegment__2UWlO {
    margin-left: 30px;
    flex: 3 1;
}
.SignContractSeg_action-button__2OrK3 {
    background-color: var(--internal-background-grey);
    color: black;
    font-size: var(--font-size-tiny);
    font-family: var(--font-family-bold);
    padding: 15px;
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
}

.SignContractSeg_button-icon__2HEuC {
    width: 20px;
    height: 20px;
}

.SignContractSeg_button-text__2j8Wj {
    font-family: var(--font-family-bold);
}

.SignContractSeg_sign-contract-panel__2OzFW {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SignContractSeg_buttons-group__31TtW {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    padding: 30px
}

.SignContractSeg_loading-container__1LEEx {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
}

.SignContractSeg_loading-indicator__2YgiF {
    border: 5px solid var(--internal-background-grey);
    border-top: 5px solid var(--theme-blue);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: SignContractSeg_spin__soP-F 1s linear infinite;
            animation: SignContractSeg_spin__soP-F 1s linear infinite;
}

@-webkit-keyframes SignContractSeg_spin__soP-F {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes SignContractSeg_spin__soP-F {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.Index_wrapper__2CMyu {
    padding: 20px;
}

.Index_keyContainer__1X1ur {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.Index_risks__3LtNe {
    border: 1px solid var(--border-theme-grey);
    border-radius: 8px;
    padding: 10px;
    padding-bottom: 6px;
    padding-right: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Index_checkboxesContainer__2sQNz {
    display: flex;
    justify-content: space-between;
    width: 250px;
}

.Index_container__vIE2z {
    position: relative;
    width: calc(100% - 40px);
    height: 350px;
    border: 1px solid var(--border-theme-grey);
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
    padding-bottom: 10px;
}

.Index_grid__EnIAS {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(to bottom,
            #f0f0f0,
            #f0f0f0 1px,
            #fff 1px,
            #fff 10%);
}

.Index_barContainer__9zvRp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 3%;
    height: 100%;
}

.Index_bar__25U0j {
    width: 100%;
    background-color: var(--theme-blue);
}

.Index_averageLine__1H1SI,
.Index_thresholdLine__3I2if {
    position: absolute;
    left: 0;
    right: 0;
    height: 9px;
}

.Index_thresholdLine__3I2if {
    border-top: 4px dashed black;
}

.Index_averageLine__1H1SI {
    border-top: 4px dashed var(--theme-blue);
}

.Index_axisLabel__E6eH7 {
    font-size: 10px;
    text-align: center;
    padding-top: 10px;
}
.Alerts_alertsContainer__1lygH {
    height: 437px;
    overflow-y: scroll;
    padding: 30px;
    padding-bottom: 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    font-family: var(--font-family-regular);
}

.Alerts_alertsContainer__1lygH::-webkit-scrollbar {
    display: none;
}

.Alerts_timePeriod__2KTos {
    margin-bottom: 20px;
}

.Alerts_timePeriodTitle__1mj92 {
    margin: 0;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: var(--font-size-tiny);
    color: black;
}

.Alerts_alert__2LtFK {
    padding: 15px;
    margin-bottom: 10px;
    font-size: var(--font-size-small);
}

.Alerts_alertTitle__difEI {
    margin: 0;
    font-weight: bold;
    margin-bottom: 5px;
    color: black;
}

.Alerts_alertDescription__1ZFRR {
    margin: 0;
    color: black;
    font-size: var(--font-size-small);
}
.Dashboard_body__eJSox {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: var(--internal-background-grey);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Dashboard_body__eJSox::-webkit-scrollbar {
    display: none;
}

.Dashboard_page-header__3wWEy {
    margin: 0;
    font-size: var(--font-size-large);
    font-family: var(--font-family-bold);
    margin-bottom: 30px;
}
.Portfolio_body__3AGBE {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: var(--internal-background-grey);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Portfolio_body__3AGBE::-webkit-scrollbar {
    display: none;
}

.Portfolio_page-header__2HXfj {
    margin: 0;
    font-size: var(--font-size-large);
    font-family: var(--font-family-bold);
    margin-bottom: 30px;
}
.Investments_body__2-rhH {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: var(--internal-background-grey);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Investments_body__2-rhH::-webkit-scrollbar {
    display: none;
}

.Investments_page-header__aBzW4 {
    margin: 0;
    font-size: var(--font-size-large);
    font-family: var(--font-family-bold);
    margin-bottom: 30px;
}
.Impact_body__2oDIc {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: var(--internal-background-grey);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Impact_body__2oDIc::-webkit-scrollbar {
    display: none;
}

.Impact_page-header__chPU9 {
    margin: 0;
    font-size: var(--font-size-large);
    font-family: var(--font-family-bold);
    margin-bottom: 30px;
}
.Spread_spread-container__2kKf9 {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
    justify-content: flex-start;
    margin: 30px;
}

.Spread_display-window__3wa4Z {
    position: relative;
    border: 1px solid var(--border-theme-grey);
    border-radius: 8px;
    padding: 10px;
    font-family: var(--font-family-regular);
    font-size: var(--font-size-tiny);
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    cursor: default;
    opacity: 1;
}

.Spread_background__1Nuoi {
    border-radius: 8px;
}
.Funds_body__mGTT0 {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: var(--internal-background-grey);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Funds_body__mGTT0::-webkit-scrollbar {
    display: none;
}

.Funds_page-header__WwpLp {
    margin: 0;
    font-size: var(--font-size-large);
    font-family: var(--font-family-bold);
    margin-bottom: 30px;
}
.News_body__37asf {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: var(--internal-background-grey);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.News_body__37asf::-webkit-scrollbar {
    display: none;
}

.News_page-header__2tCTd {
    margin: 0;
    font-size: var(--font-size-large);
    font-family: var(--font-family-bold);
    margin-bottom: 30px;
}
.Support_body__3gjRO {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: var(--internal-background-grey);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Support_body__3gjRO::-webkit-scrollbar {
    display: none;
}

.Support_page-header__3c0KT {
    margin: 0;
    font-size: var(--font-size-large);
    font-family: var(--font-family-bold);
    margin-bottom: 30px;
}
.Chat_body__1oJAI {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: var(--internal-background-grey);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Chat_body__1oJAI::-webkit-scrollbar {
    display: none;
}

.Chat_page-header__1eyqi {
    margin: 0;
    font-size: var(--font-size-large);
    font-family: var(--font-family-bold);
    margin-bottom: 30px;
}
.Settings_body__2n2aL {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: var(--internal-background-grey);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Settings_body__2n2aL::-webkit-scrollbar {
    display: none;
}

.Settings_page-header__2PnUn {
    margin: 0;
    font-size: var(--font-size-large);
    font-family: var(--font-family-bold);
    margin-bottom: 30px;
}
.MeetJoaquim_app-container__T9l6e {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.MeetJoaquim_article__3pZfR {
    font-family: var(--font-family-regular);
    background-color: var(--light-theme-blue);
    margin-top: 70px;
    padding: 60px;
}

.MeetJoaquim_title__227q0 {
    font-family: var(--font-family-bold);
    font-size: var(--font-size-large);
    color: var(--box-theme);
}

.MeetJoaquim_content__2Fo89 {
    font-size: var(--font-size-medium);
    line-height: 1.5;
    color: var(--theme-grey);
}

.MeetJoaquim_image__MPVIt {
    max-width: 100%;
    height: auto;
}
