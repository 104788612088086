body {
  margin: 0;
  font-family: "Roc Grotesk Regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
button,
input,
textarea,
select {
  line-height: 1.0;
}

code {
  font-family: "Roc Grotesk Thin", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}

@font-face {
  font-family: "Roc Grotesk Heavy";
  src: local("Roc Grotesk Heavy"),
    url("./fonts/grotesk/Kostic\ -\ Roc\ Grotesk\ Heavy.otf") format("opentype");
}

@font-face {
  font-family: "Roc Grotesk Light";
  src: local("Roc Grotesk Light"),
    url("./fonts/grotesk/Kostic\ -\ Roc\ Grotesk\ Light.otf") format("opentype");
}

@font-face {
  font-family: "Roc Grotesk Medium";
  src: local("Roc Grotesk Medium"),
    url("./fonts/grotesk/Kostic\ -\ Roc\ Grotesk\ Medium.otf") format("opentype");
}

@font-face {
  font-family: "Roc Grotesk Regular";
  src: local("Roc Grotesk Regular"),
    url("./fonts/grotesk/Kostic\ -\ Roc\ Grotesk\ Regular.otf") format("opentype");
}

@font-face {
  font-family: "Roc Grotesk Thin";
  src: local("Roc Grotesk Thin"),
    url("./fonts/grotesk/Kostic\ -\ Roc\ Grotesk\ Thin.otf") format("opentype");
}